// extracted by mini-css-extract-plugin
export const fullwidth = "coach-module--fullwidth--db4VV";
export const col1 = "coach-module--col1--3fBuS";
export const col2 = "coach-module--col2--2ZxOj";
export const col3 = "coach-module--col3--3yVZn";
export const col4 = "coach-module--col4--2zrRb";
export const col5 = "coach-module--col5--292qw";
export const col6 = "coach-module--col6--3trH6";
export const col7 = "coach-module--col7--32PcR";
export const col8 = "coach-module--col8--pdf5Q";
export const col9 = "coach-module--col9--ZIUOL";
export const col10 = "coach-module--col10--2iW-F";
export const col11 = "coach-module--col11--yssan";
export const gridContact = "coach-module--grid-contact--wymSV";
export const indexImg = "coach-module--index--img--2sSo3";
export const h1 = "coach-module--h1--3tn9w";
export const p = "coach-module--p--3mBLi";
export const indexImgPhone = "coach-module--index--img--phone--3ns6T";